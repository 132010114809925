<template>
  <div class="information">
    <el-form ref="ruleForm" label-width="120px" :model="ruleForm" class="ruleForm" :rules="rules">
      <div class="title">完善个人信息</div>
      <div class="content">
        <el-row>
          <el-col :offset="2" :span="8">
            <el-form-item label="姓名：" prop="user_name">
              <el-input v-model="ruleForm.user_name" placeholder="请填写姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item label="性别：">
              <el-select v-model.number="ruleForm.is_sex" placeholder="请选择">
                <el-option :key=1 label="男" :value=1>
                </el-option>
                <el-option :key='0' label='女' :value='0'>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :offset="2" :span="8">
            <el-form-item label="成绩：" prop="score">
              <el-input v-model="ruleForm.score" type="number" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <!-- <el-col :offset="2" :span="12">
            <el-form-item label="位次：" prop="ranking">
              <el-input v-model="ruleForm.ranking" type="number" style="width: 120px;" />
            </el-form-item>
            <div class="remarks"> 注:分数对应2024年排名为{{ ruleForm.ranking }}名，可手动输入</div>
          </el-col> -->
        </el-row>

        <el-row style="margin-bottom: 40px;">
          <el-col :span="8" :offset="4">
            <div style="color: #606266;">首选科目(2选1)</div>
            <div class="remarks-kemu-1"></div>
            <el-checkbox-group v-model="subject_list" :max="1" size="medium" @change="getSubject">
              <el-checkbox :label="4">历史</el-checkbox>
              <el-checkbox :label="1">物理</el-checkbox>
            </el-checkbox-group>

            <el-form-item label="" prop="is_wenli">

            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <div style="color: #606266;">再选科目(4选2)</div>
            <div class="remarks-kemu"> 注:必须先选择首选科目，才能选择再选科目，且再选科目至多只能选择2项。</div>
            <el-checkbox-group v-model="other_subject" :max="2">
              <el-checkbox :label="'2'">化学</el-checkbox>
              <el-checkbox :label="'3'">生物</el-checkbox>
              <el-checkbox :label="'6'">政治</el-checkbox>
              <el-checkbox :label="'5'">地理</el-checkbox>
            </el-checkbox-group>


          </el-col>

          <el-col :offset="2" :span="8" v-if="ruleForm.is_vip == 2">
            <el-form-item label="专业成绩：" prop="z_score">
              <el-input v-model="ruleForm.z_score" type="number" placeholder="输入分数" />
            </el-form-item>
          </el-col>


        </el-row>

        <el-row>
          <el-col :offset="3" :span="17">
            <el-form-item label="所在省市：">
              <el-col :span="8">
                <el-input v-model="ruleForm.province" placeholder="河南省" disabled />
              </el-col>
              <el-col :span="7" :offset="1">
                <el-select v-model="ruleForm.city" placeholder="请选择" @change="selectCity($event)">
                  <el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
              <el-col :span="7" :offset="1">
                <el-select v-model="ruleForm.district" placeholder="请选择" @change="selectSchool($event)">
                  <el-option v-for="item in districtList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="3" :span="17">
            <el-form-item label="就读中学：" prop="school">
              <el-col class="school" v-if="schoolList.length < 1">
                <el-select v-model="ruleForm.school" placeholder="请先选择所在省市">
                  <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
              <el-col class="school" v-else>
                <el-select v-model="ruleForm.school" placeholder="请选择">
                  <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="3" :span="17">
            <el-form-item label="就读班级：">
              <el-col :span="8">
                <el-input v-model="ruleForm.grade" placeholder="年级" />
              </el-col>
              <el-col :span="8" :offset="1">
                <el-input v-model="ruleForm.class" placeholder="班级" />
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button class="btn" @click="onSubmit('ruleForm')" round>确认</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import height_school from "@/assets/data/height_school.js";
export default {
  name: "Information",
  data() {
    return {
      cityList: [],
      districtList: [],
      schoolList: [],
      city: [],
      school: [],
      other_subject: [

      ],
      subject_list: [],
      ruleForm: {
        school: "",
        is_wenli: '',
        score: "",
        is_sex: 0,
        subject: '',

      },
      rules: {
        user_name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        first_subject: [
          { required: true, message: '请选择首选科目', trigger: 'change' },
        ],
        score: [
          { required: true, message: '请填写分数', trigger: 'change' },
        ],
        school: [
          { required: true, message: '请选择学校', trigger: 'change' },
        ],
      }
    };
  },
  mounted() {
    this.cityList = height_school;
    this.$fecth.post("user_info/getUserInfo").then((res) => {
      localStorage.setItem("UserInfo", JSON.stringify(res));
      delete res.profile;
      this.ruleForm = res;
      this.other_subject = JSON.parse(res.other_subject)
      if (res.subject) {
        this.subject_list = [res.subject]
      }
      this.setInfo(this.ruleForm);
    });
  },
  computed: {

  },
  filters: {
    filtersMobile(item) {
      return item.replace(item.substr(3, 4), "****");
    },
  },
  beforeDestroy() {
    const foot = document.getElementById("footer");
    foot.style.display = "block";
  },
  methods: {
    ...mapMutations(["setInfo"]),
    // 生成学校选择数据
    selectCity(value) {
      let obj = {};
      obj = this.cityList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.ruleForm.district = null;
      this.districtList = obj.children;
    },
    selectSchool(value) {
      let obj = {};
      obj = this.districtList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.ruleForm.school = null;
      this.schoolList = obj.children;
    },

    getSubject() {

      if (this.subject_list.length > 0) {

        this.ruleForm.subject = this.subject_list[0]
        if (this.ruleForm.subject == 1) {
          this.ruleForm.is_wenli = 2
        } else {
          this.ruleForm.is_wenli = 1
        }
      }
    },

    onSubmit(formName) {

      this.ruleForm.other_subject = JSON.stringify(this.other_subject)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.school) {
            this.$message({
              message: "请先选择高中",
              type: "error",
            });
            return false;
          }
          if (!this.ruleForm.score) {
            this.$message({
              message: "请先填写分数",
              type: "error",
            });
            return false;
          }

          if (!this.ruleForm.subject) {
            this.$message({
              message: "请先选择科类",
              type: "error",
            });
            return false;
          }

          if (!this.ruleForm.other_subject) {
            this.$message({
              message: "请先选择高中",
              type: "error",
            });
            return false;
          }

          this.$fecth.post("user_info/updateUserInfo", this.ruleForm).then((res) => {
            if (res) {
              this.$message({
                showClose: true,
                message: "提交成功！",
                type: "success",
              });
              localStorage.setItem("UserInfo", JSON.stringify(res));
              this.setInfo(res);
              // 回到首页
              let redirect = this.$route.query.redirect
              if (redirect) {
                setTimeout(() => {
                  this.$router.push('/');
                }, 1000);
              } else {
                setTimeout(() => {
                  this.$router.push("/");
                }, 1000);
              }


            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  },
};
</script>

<style scoped lang="less">
.information {
  width: 1200px;
  margin: 0 auto 140px;

  &:before {
    content: "";
    display: block;
    height: 100px;
  }

  .ruleForm {
    width: 800px;
    margin: auto;
    box-shadow: 5px 5px 10px #f7f7f7, 5px -5px 10px #f7f7f7,
      -5px 5px 10px #f7f7f7, -5px -5px 10px #f7f7f7;
    border-radius: 10px;

    .title {
      font-size: 18px;
      text-align: center;
      color: #2771fa;
      line-height: 70px;
      height: 70px;
      border: 2px solid #f4f4f4;
    }

    .content {
      padding: 40px;
    }
  }


}

.remarks {
  font-size: 12px;
  color: #979797;
  margin-left: 60px;
}

.remarks-kemu-1 {
  font-size: 12px;
  height: 40px;
  color: #979797;
}

.remarks-kemu {
  margin-top: 8px;
  font-size: 12px;
  color: #979797;
}
</style>


<style lang="less">
.information {
  .el-row {
    margin-top: 13px;
  }

  .el-radio__label {
    font-size: 16px;
  }

  font-size: 16px;
  background-color: #ffffff;

  .el-form-item__label {
    font-size: 16px;
  }

  .el-input__inner {
    line-height: 27px;
    height: 27px;
    background-color: #f4f4f4;
  }

  .el-form-item {
    margin-bottom: 1px;
  }

  .school {
    .el-input__inner {
      width: 390px;
    }
  }

  .btn {
    background: #288df9;
    margin-left: 500px;
    margin-top: 20px;
    width: 120px;
    height: 30px;
    line-height: 5px;
    color: white;
  }
}
</style>
